@import './scss/variables.scss';
@import './scss/reset.scss';
@import './scss/fonts.scss';
@import './scss/typo.scss';
@import './scss/container.scss';
@import './scss/menu.scss';
@import './scss/button.scss';
@import './scss/special-offer.scss';
@import './scss/hero.scss';
@import './scss/logos.scss';
@import './scss/trainers.scss';
@import './scss/download.scss';
@import './scss/directions-hero.scss';
@import './scss/pulse-zones.scss';
@import './scss/reviews.scss';
@import './scss/video-block.scss';
@import './scss/description.scss';
@import './scss/will-get.scss';
@import './scss/upcoming-classes.scss';
@import './scss/footer.scss';
@import './scss/modal.scss';
@import './scss/checkboxes.scss';
@import './scss/directions.scss';
@import './scss/timetable.scss';
@import './scss/calendar.scss';
@import './scss/mobile-filters.scss';
@import './scss/virtual-select.scss';
@import './scss/field.scss';
@import './scss/login.scss';
@import './scss/registration.scss';
@import './scss/home-description.scss';
@import './scss/studios-home.scss';
@import './scss/directions-home.scss';
@import './scss/switcher.scss';
@import './scss/vjs-custom-skin.scss';
@import './scss/profile.scss';
@import './scss/subsсriptions.scss';
@import './scss/classes.scss';
@import './scss/cost.scss';
@import './scss/studio.scss';
@import '../node_modules/css-star-rating/css/star-rating.css';
@import './scss/landing.scss';
@import './scss/404.scss';
@import './scss/gift-card.scss';
@import './scss/trainer.scss';
